import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import Lesson from '@/assets/img/advocacy-for-children/education_lesson@2x.png';
import Obj3 from '@/assets/img/advocacy-for-children/education_obj1@2x.png';
import Obj2 from '@/assets/img/advocacy-for-children/education_obj2@2x.png';
import Obj1 from '@/assets/img/advocacy-for-children/education_obj3@2x.png';
import Quiz from '@/assets/img/advocacy-for-children/education_quiz@2x.png';
import StudyCard1 from '@/assets/img/advocacy-for-children/study_card1@2x.png';
import StudyCard2 from '@/assets/img/advocacy-for-children/study_card2@2x.png';
import StudyCard3 from '@/assets/img/advocacy-for-children/study_card3@2x.png';
import StudyCard4 from '@/assets/img/advocacy-for-children/study_card4@2x.png';
import StudyCard5 from '@/assets/img/advocacy-for-children/study_card5@2x.png';
import Button from '@/components/Button';
import ButtonMoreView from '@/components/ButtonMoreView';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import { CardFlex, ObjList } from '@/components/Css/PublicPrivatePartnership';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import PageComponent from '@/components/PageComponent';
import Section, { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import Youtube from '@/components/Youtube';
import YoutubeSingle from '@/components/YoutubeSingle';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';

const youtubeData2: TabData[] = [
  {
    id: 0,
    youtubeId: `HTWUPiNsUCw`,
    title: `우리 모두 권리가 있어요!`,
  },
  {
    id: 1,
    youtubeId: `V1BFLitBkco`,
    title: `What are child rights?`,
  },
  {
    id: 2,
    youtubeId: `HCYLdtug8sk`,
    title: `What are child rights and why are they important?`,
  },
  {
    id: 3,
    youtubeId: `KCWaBrEaLRY`,
    title: `For every child, every right`,
  },
  {
    id: 4,
    youtubeId: `mI2dYmNCJVQ`,
    title: `UNICEF Children's Rights`,
  },
];
const schoolData = [
  {
    id: 0,
    img: Obj3,
    tit: `권리에 관한 교육`,
    dec: `어린이는 구조화된 환경에서 지도를 받으며 아동권리 및 유엔아동권리협약을 배웁니다.`,
  },
  {
    id: 1,
    img: Obj2,
    tit: `권리를 통한 교육`,
    dec: `어린이는 유엔아동권리협약의 기본 가치가 존중되는 환경 속에서 아동권리를 익힙니다. 아동권리교육은 모든 어린이가 평등하게 존중받으며 의미있는 참여를 할 수 있는 기회를 보장하고, 폭력 및 위험 요소로부터 어린이를 보호하며, 어린이의 언어∙문화∙종교를 존중하는 등의 인권적인 방식을 통해 이루어져야 합니다.`,
  },
  {
    id: 2,
    img: Obj1,
    tit: `권리를 위한 교육`,
    dec: `어린이는 자신과 타인의 권리를 존중하고 실현하기 위한 목소리를 내고, 권리 옹호를 위한 행동을 하며 아동권리를 익힙니다. `,
  },
];
const contactData = [
  {
    id: 1,
    tit: `문의`,
    costumer: `아동권리사업팀`,
    tel: `02-724-8581~3`,
    email: `crp@unicef.or.kr`,
  },
];

const QuizContainer = styled.div`
  position: relative;
  // margin-top: 64px;
  // padding-top: 57.14%;
  height: 45vw;
  background: #1cabe2;
  ${breakpoint(1024)} {
    height: 55vw;
  }
  ${breakpoint(640)} {
    height: 100vw;
  }
  iframe {
    display: block;
    width: 100%;
    height: 100%;
  }
  /* 
  & > * {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  } */
`;

const How = styled.div`
  margin-top: 8px;

  dt,
  dt {
    width: 50%;
  }

  .cnt-list {
    margin-top: 32px;
    line-height: 2;
    color: #1cabe2;

    & > li {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    .link-item {
      color: #2d2926;
      font-weight: 700;
      text-decoration: underline;
    }
    .c-black {
      color: #2d2926;
    }
  }
  ${breakpoint(`tablet`)} {
    margin-top: 72px;

    dt,
    dd {
      width: 100%;
    }
    dd {
      margin-top: 8px;
    }

    .cnt-list {
      margin-top: 24px;
    }
  }
`;

const SectionTop = styled(Section)`
  ${SectionHeader} {
    .flex-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      h2 {
        width: 40%;
      }
      .col-btn {
        width: 60%;
        max-width: 544px;
        .flex {
          align-items: stretch;
          margin-bottom: 0;
          & > li {
            width: 50%;
          }
        }
      }
    }
  }

  ${ObjList} {
    margin-top: 96px;

    & > li {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }

      .img-wrap {
        width: 56px;
      }
      .dec-wrap {
        width: calc(100% - 56px);
        padding-left: 24px;
        text-align: left;
      }
    }
  }

  ${breakpoint(`tablet`)} {
    ${SectionHeader} {
      .flex-wrap {
        h2,
        .col-btn {
          width: 100%;
          max-width: 100%;
        }
        .col-btn {
          margin-top: 24px;
          .flex {
            & > li {
              width: 100%;
              margin-bottom: 16px;

              &:last-child {
                margin-bottom: 0;
              }

              ${Button} {
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }
  ${breakpoint(`mobile`)} {
    ${ObjList} {
      margin-top: 72px;

      & > li {
        flex-wrap: wrap;
        margin-bottom: 24px;

        .dec-wrap {
          margin-top: 8px;
          width: 100%;
          padding: 0;
        }
      }
    }
  }
`;
const SectionCard = styled(Section)`
  ${breakpoint(`tablet`)} {
  }
`;
const SectionStudy = styled(Section)`
  article {
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${CardFlex} {
    margin-top: 64px;
    align-items: stretch;
    .box {
      display: flex;
      align-items: flex-start;
    }
    dd {
      margin-top: 8px;
    }
    .blit-list {
      & > li {
        display: block;
        margin-right: 8px;
        width: 100%;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .img-wrap {
      width: 80px;
    }

    .dec-wrap {
      width: calc(100% - 80px);
      padding-left: 16px;

      dd {
        margin-top: 8px;
      }
      .blit-list {
        & > li {
          display: inline-block;
          margin-right: 8px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    ${breakpoint(`mobile`)} {
      & > li {
        width: 50%;

        .box {
          padding: 34px 16px;
          display: block;

          .img-wrap {
            width: 100%;
            text-align: center;
            ${Image} {
              margin: 0 auto;
              max-width: 80px;
            }
          }
          .dec-wrap {
            width: 100%;
            margin-top: 16px;
          }
          .blit-list {
            & > li {
              display: inline-block;
              margin-right: 8px;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
  .btn-wrap {
    text-align: right;

    margin-top: 64px;
    ${ButtonMoreView} {
      width: 100%;
      max-width: 352px;
      text-align: left;
    }
  }

  ${breakpoint(`tablet`)} {
    .btn-wrap {
      margin-top: 48px;
      ${ButtonMoreView} {
        max-width: 100%;
      }
    }
  }
  ${breakpoint(`mobile`)} {
    ${CardFlex} {
      & > li {
        width: 50%;

        .box {
          padding: 34px 16px;
          flex-wrap: wrap;

          .img-wrap {
            width: 100%;
            text-align: center;
            ${Image} {
              margin: 0 auto;
              max-width: 80px;
            }
          }
          .dec-wrap {
            width: 100%;
            margin-top: 16px;
          }
          .blit-list {
            & > li {
              display: inline-block;
              margin-right: 8px;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
`;
const SectionQuiz = styled(Section)`
  .col-flex {
    justify-content: space-between;
    .col-left {
      width: 33.4%;

      ${ButtonMoreView} {
        max-width: 100%;
        width: 100%;
      }
      .dec {
        margin-top: 16px;
        margin-bottom: 32px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .col-right {
      width: 66.6%;
      max-width: 619px;
      ${Image} {
        max-width: 544px;
      }
    }
  }

  ${breakpoint(`tablet`)} {
    .col-flex {
      .col-left,
      .col-right {
        width: 100%;
        max-width: 100%;
      }
      .col-right {
        text-align: center;
        margin-top: 27px;

        ${Image} {
          margin: 0 auto;
        }
      }
    }
  }
`;
const SectionVideo = styled(Section)`
  ${breakpoint(`tablet`)} {
  }
`;
const SectionLesson = styled(Section)`
  ${SectionHeader} {
    padding-bottom: 0;
  }

  .btn-flex {
    margin-top: 18px;

    & > li {
      width: 50%;
      ${ButtonMoreView} {
        max-width: 100%;
        width: 100%;
      }
    }
  }
  ${breakpoint(`tablet`)} {
    .btn-flex {
      margin-top: 48px;

      & > li {
        width: 100%;
        margin-bottom: 16px;

        &:last-child() {
          margin-bottom: 0;
        }
      }
    }
  }
`;

const ContactText = styled.div`
  margin-top: 30px;
  line-height: 1.2;
  .link {
    color: #1cabe2;
  }
`;

const ClimateChange: React.FC<PageProps> = ({ location }) => {
  const [thumbs, setThumbs] = useState<CampaignData[]>([]);

  const loadThumbs = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '285', '290'],
        pagePerCount: 8,
        category: ['A025'],
      });
      const articles = data as any;
      setThumbs(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadThumbs();
  }, [loadThumbs]);

  const Study = useRef();
  const Wll = useRef();
  const scrollToStudy = () => {
    Study.current.scrollIntoView({ behavior: `smooth` });
  };
  const scrollToWll = () => {
    Wll.current.scrollIntoView({ behavior: `smooth` }, []);
  };

  return (
    <LayoutWithTitle
      location={location}
      title="권리 교육"
      description="for every child, child rights education"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-wrap">
              <h2>
                <Tit size="s1-5" color="sky" weight="normal">
                  <PageComponent id="title1">
                    너와 나 우리 모두를 위한, <br />
                    아동권리 교육
                  </PageComponent>
                </Tit>
              </h2>
              <div className="col-btn">
                <ul className="flex">
                  <li>
                    <Button full onClick={scrollToStudy}>
                      아동권리 배우기
                    </Button>
                  </li>
                  <li>
                    <Button full onClick={scrollToWll}>
                      지속가능발전목표 배우기
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
            <p className="header-dec">
              <PageComponent id="content1">
                아동권리교육(Child Rights Education)이란, 유엔아동권리협약의
                조항과 원칙을 배우고 아동권리를 기반으로 생각하는 법을 배우는
                것입니다. 이를 통해, 어린이를 비롯해 가정, 학교, 지역사회, 국가
                모두가 아동권리 실현을 위한 역량을 키웁니다. 유니세프
                아동권리교육은 권리에 대해 배우고, 권리를 통해 배우며, 권리를
                위해 배우는 것을 포함합니다.
              </PageComponent>
            </p>
          </SectionHeader>
          <PageComponent id="youtube1">
            <YoutubeSingle videoId="KfooEfLOpeI" />
          </PageComponent>

          <ObjList className="flex">
            {schoolData.map((row, index) => (
              <li key={index}>
                <div className="img-wrap">
                  <Image pcSrc={row.img} mobileSrc={row.img} />
                </div>
                <div className="dec-wrap">
                  <Tit size="s4">{row.tit}</Tit>
                  <p className="dec">{row.dec}</p>
                </div>
              </li>
            ))}
          </ObjList>
        </Container>
      </SectionTop>

      <SectionCard className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc sec-header">
            <h2>
              <Tit size="s1">
                아동권리, <br />
                배우면 무엇이 좋은가요?
              </Tit>
            </h2>
          </SectionHeader>
          <CardFlex className="flex">
            <li>
              <div className="card">
                <Tit size="s3" color="white">
                  아동
                </Tit>
                <ul className="blit-list">
                  <li>자신이 가진 권리와 권리의 특성에 대해 알 수 있습니다.</li>
                  <li>
                    민주주의와 세계시민의식을 뒷받침해 줄 태도를 키웁니다.
                  </li>
                  <li>
                    타인의 권리 보호를 위한 긍정적 행동을 취할 수 있는 역량을
                    기릅니다.
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div className="card">
                <Tit size="s3" color="white">
                  교사
                </Tit>
                <ul className="blit-list">
                  <li>학습자 중심의 상호적 교육을 진행하는데 도움이 됩니다.</li>
                  <li>
                    비판적 사고, 갈등 해결, 공감 및 이해를 강화하는데 도움이
                    됩니다.
                  </li>
                  <li>
                    보편적 아동권리를 기반으로 기존 이념적 차이를 극복하는데
                    도움이 됩니다.
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div className="card">
                <Tit size="s3" color="white">
                  가족 및 지역사회 구성원
                </Tit>
                <ul className="blit-list">
                  <li>권리와 글로벌 이슈에 관한 어린이의 지식을 공유합니다.</li>
                  <li>
                    권리 존중 기반 아동의 향상된 의사소통 및 행동으로 혜택을
                    받습니다.
                  </li>
                  <li>
                    어린이가 수행한 실질적 지역사회 이니셔티브의 혜택을
                    받습니다.
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div className="card">
                <Tit size="s3" color="white">
                  정부
                </Tit>
                <ul className="blit-list">
                  <li>인권 관련 의무 이행에 도움이 됩니다.</li>
                  <li>
                    교육 커리큘럼 접근 방식 관련 프레임워크를 제공받습니다.
                  </li>
                  <li>교육의 질 향상을 위한 노력에 기여합니다.</li>
                </ul>
              </div>
            </li>
          </CardFlex>
        </Container>
      </SectionCard>

      <SectionStudy className="by-sub-main-layout" ref={Study}>
        <Container>
          <SectionHeader className="with-desc sec-header">
            <h2>
              <Tit size="s1">
                아동권리, <br />
                어떻게 배워야 할까요?
              </Tit>
            </h2>
          </SectionHeader>
          <div className="article-wrap">
            <article>
              <Tit size="s1-5" color="sky" weight="normal">
                나에게 맞는 아동권리 학습 자료 찾기
              </Tit>
              <QuizContainer style={{ background: `#fff` }}>
                <iframe
                  src={`${process.env.BACKEND_URL}/unicef/api/quiz`}
                  title="Child Rights Quiz"
                  allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  frameBorder="0"
                  scrolling="no"
                  vspace="0"
                  // style={{ top: `-150px`, height: `800px` }}
                />
              </QuizContainer>
            </article>
            <article>
              <Tit size="s2">주제별 심화 학습을 하고 싶다면</Tit>

              <CardFlex className="flex t2">
                <li>
                  <div className="box">
                    <div className="img-wrap">
                      <Image pcSrc={StudyCard1} mobileSrc={StudyCard1} />
                    </div>
                    <dl className="dec-wrap">
                      <dt>
                        <Tit size="s4" color="sky">
                          식량
                        </Tit>
                      </dt>
                      <dd>
                        <ul className="blit-list">
                          <li>내가 농부라면</li>
                          <li>내가 버린 음식들</li>
                          <li>세상을 바꾸는 한 끼</li>
                        </ul>
                      </dd>
                    </dl>
                  </div>
                </li>
                <li>
                  <div className="box">
                    <div className="img-wrap">
                      <Image pcSrc={StudyCard2} mobileSrc={StudyCard2} />
                    </div>
                    <dl className="dec-wrap">
                      <dt>
                        <Tit size="s4" color="sky">
                          난민
                        </Tit>
                      </dt>
                      <dd>
                        <ul className="blit-list">
                          <li>떠도는 아이들</li>
                          <li>이바인과 체계</li>
                        </ul>
                      </dd>
                    </dl>
                  </div>
                </li>
                <li>
                  <div className="box">
                    <div className="img-wrap">
                      <Image pcSrc={StudyCard3} mobileSrc={StudyCard3} />
                    </div>
                    <dl className="dec-wrap">
                      <dt>
                        <Tit size="s4" color="sky">
                          평화 / 협력
                        </Tit>
                      </dt>
                      <dd>
                        <ul className="blit-list">
                          <li>안전한 배움터(Safe to Learn)</li>
                          <li>학교에 가지 못하는 아이들</li>
                        </ul>
                      </dd>
                    </dl>
                  </div>
                </li>
                <li>
                  <div className="box">
                    <div className="img-wrap">
                      <Image pcSrc={StudyCard4} mobileSrc={StudyCard4} />
                    </div>
                    <dl className="dec-wrap">
                      <dt>
                        <Tit size="s4" color="sky">
                          환경
                        </Tit>
                      </dt>
                      <dd>
                        <ul className="blit-list">
                          <li>나의 생활 나의 지구</li>
                          <li>모두를 위한 기후변화교육</li>
                        </ul>
                      </dd>
                    </dl>
                  </div>
                </li>
                <li>
                  <div className="box">
                    <div className="img-wrap">
                      <Image pcSrc={StudyCard5} mobileSrc={StudyCard5} />
                    </div>
                    <dl className="dec-wrap">
                      <dt>
                        <Tit size="s4" color="sky">
                          교육
                        </Tit>
                      </dt>
                      <dd>
                        <ul className="blit-list">
                          <li>세상을 바꾸는 힘</li>
                          <li>목표 달성을 위해 함께 해요</li>
                        </ul>
                      </dd>
                    </dl>
                  </div>
                </li>
              </CardFlex>
              <div className="btn-wrap">
                <LinkSafe
                  to="/what-we-do/database?category=KW06"
                  className="link-item"
                >
                  <ButtonMoreView mode="sky">
                    아동권리 교육자료 바로가기{` `}
                  </ButtonMoreView>
                </LinkSafe>
              </div>
            </article>
          </div>
        </Container>
      </SectionStudy>

      <SectionQuiz className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc t2">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                퀴즈로 배우는 아동권리
              </Tit>
            </h2>
            <p className="header-dec">
              총 3단계로 구성된 퀴즈를 풀다 보면 어느 순간 아동권리 전문가가
              되는 Kahoot! 아동권리 퀴즈 게임!
              <br />총 17개 언어로 제작되어 누구나 퀴즈로 아동권리를 배울 수
              있습니다.
            </p>
          </SectionHeader>

          <div className="col-flex flex">
            <div className="col-left">
              <LinkSafe target="_blank" to="https://bit.ly/2posLgB">
                <ButtonMoreView mode="sky">
                  1단계 아동권리 소개하기
                </ButtonMoreView>
              </LinkSafe>
              <p className="dec">
                아동권리협약을 소개하는 퀴즈를 풀며 CRC가 무엇인지 이해할 수
                있습니다.
              </p>
              <LinkSafe target="_blank" to="https://bit.ly/2Jy74RU">
                <ButtonMoreView mode="sky">
                  2단계 아동권리 자세히 알기
                </ButtonMoreView>
              </LinkSafe>
              <p className="dec">
                아동권리협약(CRC)에 대해 더 많이 배울 수 있는 퀴즈! 1단계보다 좀
                더 많은 내용을 포괄적으로 다루는 Kahoot 퀴즈를 풀며 협약을
                자세히 이해할 수 있습니다.
              </p>
              <LinkSafe target="_blank" to="https://bit.ly/2pg5k9k">
                <ButtonMoreView mode="sky">
                  3단계 아동권리 전문가 되기
                </ButtonMoreView>
              </LinkSafe>
              <p className="dec">
                아동권리 수준을 업그레이드! 지금까지 배운 아동권리협약(CRC)을
                떠올리며 퀴즈의 정답을 맞히고 아동권리 전문가에 도전해봅니다.
              </p>
            </div>
            <div className="col-right">
              <Image pcSrc={Quiz} mobileSrc={Quiz} />
            </div>
          </div>
        </Container>
      </SectionQuiz>

      <SectionVideo className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                영상으로 배우는 아동권리
              </Tit>
            </h2>
          </SectionHeader>
          <Youtube tabs={youtubeData2} />
        </Container>
      </SectionVideo>

      <SectionLesson className="by-sub-main-layout" ref={Wll}>
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">World’s Largest Lesson</Tit>
            </h2>
            <p className="header-dec">
              2016년부터 2030년까지 빈곤 퇴치, 양질의 교육 환경, 환경보호,
              불평등 해소 등을 위해 전 세계가 함께 수립한 17가지 목표인
              지속가능발전 목표(Sustainable Development Goals, SDGs)를 배우고
              지구촌 문제해결을 위해 고민하고 행동할 수 있도록 마련된 전 세계
              교육 캠페인 입니다. 유니세프는 교사들이 학교에서 World’s Largest
              Lesson을 진행할 수 있도록 수업자료와 가이드를 제공합니다.
            </p>
          </SectionHeader>
          <How
            className="flex"
            css={`
              ${breakpoint(640)} {
                margin-top: 72px;
              }
            `}
          >
            <dt>
              <Tit size="s2" color="sky">
                참여 방법
              </Tit>
              <ul className="cnt-list">
                <li>
                  ① <strong>활동 신청: </strong>
                  <LinkSafe
                    to="/what-we-do/advocacy-for-children/apply"
                    className="link-item"
                  >
                    신청하기
                  </LinkSafe>
                </li>
                <li>
                  ② <strong>교육 자료 확인: </strong>
                  <LinkSafe to="/what-we-do/database" className="link-item">
                    교육 자료 받기
                  </LinkSafe>
                </li>
                <li>
                  ③ <strong>활동 진행: </strong>
                  <span className="c-black">
                    교육과정 및 수업계획에 따라 자율적으로 진행
                  </span>
                </li>
                <li>
                  ④ <strong>교육 후기 작성: </strong>cre@unicef.or.kr
                  <span className="c-black">로 발송</span>
                </li>
                <li>
                  ⑤ <strong>참가 인증 받기: </strong>
                  <span className="c-black">참가 확인증과 기념품 받기</span>
                </li>
              </ul>
            </dt>
            <dd>
              <Image pcSrc={Lesson} mobileSrc={Lesson} />
            </dd>
          </How>
          <ul className="btn-flex flex">
            <li>
              <LinkSafe
                to="/what-we-do/advocacy-for-children/apply"
                className="link-item"
              >
                <ButtonMoreView mode="sky" id="applyBtn">
                  활동 신청하기
                </ButtonMoreView>
              </LinkSafe>
            </li>

            <li>
              <LinkSafe
                to="/what-we-do/database?category=KW08#tagBox"
                className="link-item"
              >
                <ButtonMoreView mode="sky">
                  World's Largest Lesson 바로 가기
                </ButtonMoreView>
              </LinkSafe>
            </li>
          </ul>
        </Container>
      </SectionLesson>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A025" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {thumbs.length > 0 && (
            <RelativeBoardCard
              newslist={thumbs}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>
      <Section className="by-sub-main-layout">
        <Container>
          <ContactUs itemData={contactData} />
          <ContactText>
            ※ 아동친화도시 관련 교육은 아동친화도시팀 (
            <LinkSafe to="mailto:cfc@unicef.or.kr" className="link">
              cfc@unicef.or.kr
            </LinkSafe>
            )으로 문의해 주시기 바랍니다.
          </ContactText>
        </Container>
      </Section>
    </LayoutWithTitle>
  );
};

export default ClimateChange;
